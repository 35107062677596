<template>
    <div class="log-index">
        <md-tabs @change="tabChange" ref="tabs">
            <md-tab :md-active="tabIndex==0" md-label="Logs">
                <log-list ref="list" v-if="tabIndex==0"></log-list>
            </md-tab>

            <md-tab :md-active="tabIndex==1" md-label="Log" v-if="tabIndex==1">
                <log-detail :id="id" ref="detail" v-if="tabIndex==1"></log-detail>
            </md-tab>
        </md-tabs>
    </div>
</template>


<script>
import LogList from "@/components/Log/LogList";
import LogDetail from "@/components/Log/LogDetail";
export default {
    props: ["id"],
    components: {
        LogList,
        LogDetail
    },
    data() {
        return {
            tabIndex: 0
        };
    },
    watch: {
        $route: {
            immediate: true,
            handler() {
                if (!this.id) {
                    this.tabIndex = 0;
                } else {
                    this.tabIndex = 1;
                }
            }
        }
    },
    methods: {
        tabChange(index) {
            if (index == 0) {
                this.$router.push("/admin/logs");
            }
            if (index == 1) {
                this.$router.push(`/admin/logs/${this.id}`);
            }
        }
    }
};
</script>