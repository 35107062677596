<template>
    <div class="log-list">
        <list :fs="logFields" api="logs" :showAction="false" :showSearch="false"></list>
    </div>
</template>
<script>
import { logFields } from "@/config";
import List from "@/components/List";
export default {
    components: {
        List
    },
    computed: {
        logFields() {
            return logFields;
        }
    }
};
</script>
