<template>
    <div class="log-detail">
        <md-card>
            <md-card-header>
                <h1 v-if="log.name">{{log.path}}</h1>
            </md-card-header>
            <md-card-content>
                <div class="ctrl">
                    <md-button class="md-dense md-raised" :class="{'md-accent':onlyError}" @click="onlyError=!onlyError">Only Show Error</md-button>
                    <!--L-->
                    <md-button v-if="false" class="md-dense md-primary md-raised" @click="download">Download</md-button>
                    <span>{{log.size}} KiB</span>
                </div>
                <pre class="content" v-for="(line,ix) in lines" :key="ix" :class="{error:/error/i.test(line)}">{{line}}</pre>
            </md-card-content>
        </md-card>
    </div>
</template>
<script>
export default {
    props: ["id"],
    data() {
        return {
            log: {},
            onlyError: false
        };
    },
    computed: {
        lines() {
            let content = this.log.content || "";
            let lines = content
                .replace(/\n\r/g, "\n")
                .replace(/\r\n/g, "\n")
                .match(/\[[\s\S]*?\n(?=[\[|\n])?/g);
            if (this.onlyError) {
                return lines.filter(str => /error/i.test(str));
            } else {
                return lines;
            }
        }
    },
    methods: {
        async loadLog() {
            let result = await this.$store.dispatch("crud/get", {
                api: `logs/${this.id}`
            });
            this.log = result;
        },
        async download() {
            let result = await this.$store.dispatch("crud/download", {
                api: `logs/${this.id}`,
                params: {
                    download: true
                },
                filename: `${this.id}.log`
            });
            // window.open(`/api/logs/${this.id}?download=true`);
        }
    },
    created() {
        this.loadLog();
    }
};
</script>
<style lang="less">
.log-detail {
    .md-card {
        max-width: 1400px;
        margin: auto;
    }
    .ctrl {
        display: flex;
        align-items: center;
    }
    .content {
    }
    .error {
        color: #f44336;
    }
    pre {
        margin: 0;
    }
}
</style>


